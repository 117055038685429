import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import styles from './tarifs.module.scss'
import Layout from '../components/layout'
import HeroPage from '../components/hero-page'
//import Arrow from '../images/icon/arrow-down.png'


class Tarifs extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			value: '',
			brand:'Selectionnez une marque',
			phone:'Selectionnez un modèle',
			battery:'',
			originalScreen:'',
			screenProtector:'',
			year:'',
			chargingPort:'',
            options : [],
		};
		this.initialState = { ...this.state }
		this.handleChange = this.handleChange.bind(this);
		this.handlePhoneChange = this.handlePhoneChange.bind(this);
	}

    resetState(){
        this.setState(this.initialState);	
    }

	displayBrands(){
		const brands = get(this, 'props.data.allBrandsShopify.group')
        return brands.map(({ fieldValue }) => {
            return( <option key={ fieldValue } value={fieldValue} brand={fieldValue}>{ fieldValue }</option> );
        });
	}

    getOptionsByBrand(){
        const phones = get(this, 'props.data.allProductsShopify.edges')
        let options = [];
        phones.map(({ node}) => {
			if(node.brand === this.state.value){
                options.push({ id:node.model, model: node.model });
			}
		});

        this.setState({ options });	
    }

    displayPhones(){
        this.getOptionsByBrand()
	}

	handleChange(event){
        this.setState({
            value: event.target.value,
            phone: 'Selectionnez un modèle',
            brand: event.target.selectedOptions[0].getAttribute('brand')
        }, () => {
            this.displayPhones();
        });	
	}

	handlePhoneChange(event){
		this.setState({
            value: event.target.value,
            phone: event.target.selectedOptions[0].getAttribute('phone')
        });
	}

	displayPriceData(){
		const prices = get(this, 'props.data.allProductsShopify.edges')
		return prices.map(({ node}) => {
			if(node.model === this.state.value){
				return( 
					<div key={node.model} className={styles.blockpriceContentListWrapper} >
                        <ul className={styles.blockpriceContentList}><h3>Réparations :</h3>
                            {node.variants.map((object, i) => <li key={i}>{object.title} : {object.price === '0.00' ? 'N/D' : object.price+ '$ + tx'}</li>)}
                        </ul>
                        <ul className={styles.blockpriceContentList}><h3>Accessoires et services :</h3>
						<li >Protecteur écran : 20$ + tx</li>
                        <li >Restauration : 30$ + tx</li>
					</ul>
					</div>
				);
			}
		});
	}

	render() {

		const siteTitle = 'Nos tarifs pour réparations, entretiens et accessoires mobiles'
        const siteDesc = 'vous trouverez ici nos tarifs pour réparations, entretiens et accessoires mobiles en fonction de votre modèle d\'appareil'
		return (
			<Layout location={this.props.location}>
				<div className="page-wrapper">
					<Helmet title={siteTitle}>
                        <meta name="description" content={siteDesc} />
                    </Helmet>
					<HeroPage title={siteTitle} imgpage="tarifs"/>
					<section>
						<div className={`${styles.blockpriceContent} container`}>
							<h2>Sélectionnez une marque et un modèle pour afficher les tarifs</h2>
							<div className={styles.blockpriceContentSelect}>
								<div className={styles.selectstyle}>
									<select value={this.state.value} brand={this.state.brand} onChange={this.handleChange}>
										<option brand={this.state.brand}>{this.state.brand}</option>
										{ this.displayBrands() }
									</select>
								</div>
								<div className={styles.selectstyle}>
									<select value={this.state.value} phone={this.state.phone} onChange={this.handlePhoneChange}>
										<option>{this.state.phone}</option>
										{this.state.options.map(item => (
                                            <option key={item.id} value={item.id} phone={item.model }>{item.model}</option>
                                        ))}
                                        
									</select>
								</div>
							</div>

							{this.displayPriceData()}

                            <div className={styles.blockpriceContentCta} >
                                <p>Si aucun résultat ne correspond à votre recherche, n'hésitez pas :</p><a href="/contact" className="blockBtnwrapperItem btn btn--primary">Appelez-nous</a>
                            </div>
						</div>
					</section>
				</div>
			</Layout>
		)
	}
}

export default Tarifs

export const pageQuery = graphql`
query TarifsQuery {
	allBrandsShopify {
        group(field: name) {
            fieldValue
        }
    }
    allProductsShopify(sort: {order: ASC, fields: model}) {
        edges {
            node {
                model
                type
                brand
                variants {
                    title
                    price
                }
            }
        }
    }
}
`